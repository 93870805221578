import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { Box, styled } from '@mui/material';
import { uploadBizlyOSImages } from 'cloudinary';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image } from './Image';
import { ImageInputFieldHeader } from './ImageInputFieldHeader';
import { SortableImageItem } from './SortableImageItem';
import { SmallUploadContent, UploadContent } from './UploadContent';

const IMAGE_DEFAULT_LIMIT = 6;

const EmptyUploadContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing(0.5),
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    border: '1px solid ' + getColor(EColors.bizlyOSPrimary),
    borderRadius: shape.borderRadius,
    cursor: 'pointer',
    padding: spacing(2.5),
}));

const SmallUploadContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    width: '100%',
    aspectRatio: 1,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    border: '1px solid ' + getColor(EColors.bizlyOSPrimary),
    borderRadius: shape.borderRadius,
    gap: spacing(0.5),
    padding: spacing(0.5),
    cursor: 'pointer',
}));

const Grid = styled(Box)(({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(3, 1fr)`,
    gridGap: spacing(1.25),
    marginBottom: spacing(1.25),
}));

const handelImageUpload = async (file: File, id: string, fileName: string) => {
    try {
        return await uploadBizlyOSImages(file, id, fileName);
    } catch (error) {
        return null;
    }
};

export interface ImageType {
    id: string;
    srcUrl: string;
    isSelected: boolean;
}

type ImageInputFieldProps = {
    label: string;
    required: boolean;
    limit?: number;
    error: boolean;
    onChange: (images: Bizly.VenueImage[]) => void;
    value: Bizly.VenueImage[];
    listingId: string;
};

function ImageInputField({
    label,
    required = false,
    limit = IMAGE_DEFAULT_LIMIT,
    value: images,
    onChange: setImages,
    error,
    listingId,
}: ImageInputFieldProps) {
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [active, setActive] = useState<Bizly.VenueImage | null>(null);
    const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { delay: 250, tolerance: 200 } }));

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            if (acceptedFiles.length === 0) return;

            if (limit && acceptedFiles.length + images.length > limit) {
                return;
            }

            setIsUploading(true);

            // Process files in parallel but preserve order
            Promise.all(
                acceptedFiles.map(async file => {
                    const fileName = file.name.split('.')[0];
                    const uploadResult = await handelImageUpload(file, listingId, fileName);
                    return uploadResult
                        ? {
                              id: crypto.randomUUID(),
                              srcUrl: uploadResult.secure_url,
                              name: fileName,
                          }
                        : null;
                })
            )
                .then(results => {
                    const newImages = results.filter(Boolean) as Bizly.VenueImage[];
                    setImages([...images, ...newImages]);
                })
                .finally(() => setIsUploading(false));
        },
        [images, limit, listingId, setImages]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/webp': ['.webp'],
            'image/svg+xml': ['.svg'],
            'image/gif': ['.gif'],
        },
        noClick: isUploading,
        maxFiles: limit,
        maxSize: 1024 * 1024 * 3, // 3mb
    });

    function handleSelectImage(id: string) {
        if (!id) return;
        const newImages = images.map(image => {
            if (image.id !== id) return image;
            return {
                ...image,
                isSelected: !image.isSelected,
            };
        });

        setImages(newImages);
    }

    function handleDragStart(event: DragEndEvent) {
        const image = images.find(image => image.id === event.active.id);
        setActive(image || null);
    }

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (!active || !over || active.id === over.id) return;

        let oldIndex = 0;
        let newIndex = 0;

        images.forEach((image, index) => {
            if (image.id === active.id) oldIndex = index;
            if (image.id === over.id) newIndex = index;
        });

        const newImages = arrayMove(images, oldIndex, newIndex);

        setImages(newImages);

        setActive(null);
    }

    function handleDragCancel() {
        setActive(null);
    }

    return (
        <Box flex={1} display="flex" flexDirection="column">
            <ImageInputFieldHeader
                label={label}
                required={required}
                limit={limit}
                error={error}
                isUploading={isUploading}
                value={images}
                onChange={setImages}
            />
            {images.length > 0 ? (
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragCancel={handleDragCancel}
                >
                    <SortableContext items={images} strategy={rectSortingStrategy}>
                        <Grid>
                            {images.map(image => (
                                <SortableImageItem
                                    key={image.id}
                                    id={image.id}
                                    srcUrl={image.srcUrl}
                                    isSelected={image.isSelected}
                                    onItemSelect={handleSelectImage}
                                    isDragable={images.length > 1}
                                />
                            ))}
                            {images.length < limit && (
                                <SmallUploadContainer {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <SmallUploadContent isDragActive={isDragActive} isUploading={isUploading} />
                                </SmallUploadContainer>
                            )}
                        </Grid>
                    </SortableContext>
                    <DragOverlay adjustScale={true}>
                        {active ? <Image id={`${active.id}`} srcUrl={active.srcUrl} isSelected={false} /> : null}
                    </DragOverlay>
                </DndContext>
            ) : (
                <EmptyUploadContainer {...getRootProps()}>
                    <input {...getInputProps()} />
                    <UploadContent isDragActive={isDragActive} />
                </EmptyUploadContainer>
            )}
        </Box>
    );
}

export default ImageInputField;
