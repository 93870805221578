import { Spinner } from 'components/Spinner';
import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BorderedContainer } from '../GridContainer';
import {
    CONTACT_INFO_PATH,
    EVENT_SPACES_PATH,
    EXPERIENCE_AMENITIES_PATH,
    FAQS_PATH,
    GENERALINFO_PATH,
    GUEST_ROOMS_PATH,
    HIGHLIGHTS_PATH,
    OTHER_SPACES_PATH,
    PHOTOS_PATH,
    POLICIES_PATH,
} from './ListingsSubNavigation';

type ListingBodyProps = {
    listing: Bizly.Venue | undefined;
    isLoading: boolean;
};

const ListingGeneralInfo = lazy(() => import('./ListingGeneralInfo'));
const ListingPhotos = lazy(() => import('./ListingPhotos'));
const ListingContactInfo = lazy(() => import('./ListingContactInfo/ListingContactInfo'));
const ListingExperienceAndAmenities = lazy(() => import('./ListingExperienceAndAmenities'));
const ListingHighlights = lazy(() => import('./ListingHighlights'));
const ListingGuestRooms = lazy(() => import('./ListingGuestRooms'));
const ListingEventSpaces = lazy(() => import('./EventSpaces/ListingEventSpaces'));
const ListingOtherSpaces = lazy(() => import('./ListingOtherSpaces'));
const ListingFAQs = lazy(() => import('./ListingFAQs'));
const ListingPolicies = lazy(() => import('./ListingPolicies'));

function ListingBody({ listing, isLoading }: ListingBodyProps) {
    if (isLoading || !listing) {
        return (
            <BorderedContainer padding={2}>
                <Spinner />
            </BorderedContainer>
        );
    }

    return (
        <Suspense fallback={<Spinner />}>
            <Routes>
                <Route path={GENERALINFO_PATH} element={<ListingGeneralInfo listing={listing} />} />
                <Route path={PHOTOS_PATH} element={<ListingPhotos listing={listing} />} />
                <Route path={CONTACT_INFO_PATH} element={<ListingContactInfo />} />
                <Route path={EXPERIENCE_AMENITIES_PATH} element={<ListingExperienceAndAmenities listing={listing} />} />
                <Route path={HIGHLIGHTS_PATH} element={<ListingHighlights listing={listing} />} />
                <Route path={GUEST_ROOMS_PATH} element={<ListingGuestRooms listing={listing} />} />
                <Route path={EVENT_SPACES_PATH} element={<ListingEventSpaces listing={listing} />} />
                <Route path={OTHER_SPACES_PATH} element={<ListingOtherSpaces />} />
                <Route path={FAQS_PATH} element={<ListingFAQs listing={listing} />} />
                <Route path={POLICIES_PATH} element={<ListingPolicies />} />
            </Routes>
        </Suspense>
    );
}

export default ListingBody;
