import HelpIcon from '@mui/icons-material/Help';
import { Box, styled } from '@mui/material';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { InquiryManager } from './InquiryActions/InquiryManager';
import { ProposalManager } from './ProposalActions/ProposalManager';

const Container = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    border: `.0625rem solid ${getColor(EColors.bizlyOSBorder)}`,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    padding: spacing(2.5),
}));

export const ProposalStatus = {
    ACCEPTED: 'Proposal Sent',
    WITHDRAWN: 'Proposal Withdrawn',
    EXPIRED: 'Proposal Expired',
    NEW_INQUIRY: 'New Inquiry',
    REJECTED: 'Inquiry Rejected',

    // intermediate states for the decline and accept flow
    DECLINING: 'declining',
    INITIATING_WITHDRAWAL: 'initiating withdrawal',
    WITHDRAWING: 'withdrawing',
    INITIATE_EXTEND_PROPOSAL: 'Initiate Extend Proposal',
    PROPOSAL_EXTENDED: 'Proposal Extended',
} as const;

const getProposalStatus = (proposalStatus?: string) => {
    switch (proposalStatus) {
        // Proposal Statuses
        case ProposalStatus.ACCEPTED:
        case ProposalStatus.WITHDRAWN:
        case ProposalStatus.EXPIRED:
        case ProposalStatus.INITIATE_EXTEND_PROPOSAL:
        case ProposalStatus.PROPOSAL_EXTENDED:
            return true;

        // Inquiry Statuses
        case ProposalStatus.NEW_INQUIRY:
        case ProposalStatus.REJECTED:
            return false;
        default:
            return false;
    }
};

export const InquiryAction = () => {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry } = useGetProposalInquiry(venueId, proposalId);

    const { proposal = {} } = proposalInquiry || {};

    const proposalStatus = proposal.status?.friendlyName;

    const isProposal = getProposalStatus(proposalStatus);

    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            {proposalStatus && (
                <Container>
                    {isProposal ? (
                        <ProposalManager proposalStatus={proposalStatus} />
                    ) : (
                        <InquiryManager proposalStatus={proposalStatus} />
                    )}
                </Container>
            )}

            <Container>
                <Box display="flex" flexDirection="row" gap={1.25}>
                    <HelpIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                    <Box>
                        <Body1 fontWeight={600}>Got Questions?</Body1>
                        <Body2>
                            Email us at{' '}
                            <a href="mailto:concierge@bizly.com" style={{ color: getColor(EColors.bizlyOSPrimary) }}>
                                concierge@bizly.com
                            </a>
                            . We're here to help!
                        </Body2>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};
