import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry, useSendProposal } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { EColors, getColor } from 'theme';
import { DEFAULT_CURRENCY_SYMBOL, calculateEventSpacesData, calculateGuestRoomsData } from '../../utils';

export const Container = styled(Box)({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

export const Body = styled(Box)(({ theme: { spacing } }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    overflowY: 'auto',
    padding: spacing(2.5),
}));

export const Footer = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
    padding: spacing(2.5),
}));

const Card = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(1.25),
    borderRadius: shape.borderRadius,
    border: '.0313rem solid ' + getColor(EColors.bizlyOSBorder),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1.25),
}));

const CardRow = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    gap: spacing(1.25),
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const TotalCard = styled(Card)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.pureBlack),
}));

const Title = styled(Body2)(({ theme: { getColor, EColors, spacing } }) => ({
    paddingBottom: spacing(1.25),
    borderBottom: '.0313rem solid ' + getColor(EColors.bizlyOSBorder),
    fontWeight: 500,
}));

export const Divider = styled(Box)(({ theme: { getColor, EColors } }) => ({
    width: 'calc(100% + 2.5rem)',
    transform: 'translateX(-1.25rem)',
    height: '.0313rem',
    backgroundColor: getColor(EColors.bizlyOSBorder),
}));

const InfoContainer = styled(Box)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    display: 'flex',
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    backgroundColor: getColor(EColors.bizlyOSWarning, 0.1),
    gap: spacing(1.25),
}));

export function ProposalSummary() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);
    const { mutate: sendProposal } = useSendProposal(venueId, proposalId);

    const guestRooms = proposalInquiry?.guestRooms || [];
    const eventSpaces = proposalInquiry?.eventSpaces || [];
    const proposal = proposalInquiry?.proposal;
    const currencySymbol = proposal?.currency?.symbol || DEFAULT_CURRENCY_SYMBOL;
    const proposalStatus = proposalInquiry?.proposal?.status?.friendlyName;
    const hasAssignedContact = proposalInquiry?.contact;

    const guestRoomsData = useMemo(
        () => calculateGuestRoomsData(proposalInquiry?.guestRooms, proposalInquiry?.proposal),
        [proposalInquiry?.guestRooms, proposalInquiry?.proposal]
    );

    const eventSpacesData = useMemo(
        () => calculateEventSpacesData(proposalInquiry?.eventSpaces, proposalInquiry?.proposal),
        [proposalInquiry?.eventSpaces, proposalInquiry?.proposal]
    );

    const { roomRateTotal, occupancyTax, totalResortFee, roomblockTotal } = guestRoomsData;
    const { eventSpacesRateTotal, eventSpaceFbMinimum, salesTax, serviceCharge, gratuity, eventSpacesTotal } =
        eventSpacesData;

    const handleSendProposal = async () => {
        await sendProposal();
    };

    if (isLoading || !proposalInquiry) return null;

    return (
        <Container>
            <Body>
                {guestRooms.length > 0 && (
                    <Card>
                        <Title>Room Block</Title>
                        <CardRow>
                            <Body2>Rate</Body2>
                            <Body2>
                                {currencySymbol}
                                {roomRateTotal.toFixed(2)}
                            </Body2>
                        </CardRow>
                        <CardRow>
                            <Body2>Occupancy Tax ({proposal?.occupancyTax || 0}%)</Body2>
                            <Body2>
                                {currencySymbol}
                                {occupancyTax.toFixed(2)}
                            </Body2>
                        </CardRow>
                        <CardRow>
                            <Body2>Resort Fee</Body2>
                            <Body2>
                                {currencySymbol}
                                {totalResortFee.toFixed(2)}
                            </Body2>
                        </CardRow>
                        <CardRow>
                            <Body2 fontWeight={500}>Room Block Total</Body2>
                            <Body2 fontWeight={500}>
                                {currencySymbol}
                                {roomblockTotal.toFixed(2)}
                            </Body2>
                        </CardRow>
                    </Card>
                )}

                {eventSpaces.length > 0 && (
                    <Card>
                        <Title>Spaces</Title>
                        <CardRow>
                            <Body2>Rate</Body2>
                            <Body2>
                                {currencySymbol}
                                {eventSpacesRateTotal.toFixed(2)}
                            </Body2>
                        </CardRow>
                        {!proposal?.useDdr && (
                            <CardRow>
                                <Body2>F&B Minimum</Body2>
                                <Body2>
                                    {currencySymbol}
                                    {eventSpaceFbMinimum.toFixed(2)}
                                </Body2>
                            </CardRow>
                        )}
                        <CardRow>
                            <Body2>Sales Tax ({proposal?.salesTax}%)</Body2>
                            <Body2>
                                {currencySymbol}
                                {salesTax.toFixed(2)}
                            </Body2>
                        </CardRow>
                        <CardRow>
                            <Body2>Service Charge ({proposal?.serviceCharge}%)</Body2>
                            <Body2>
                                {currencySymbol}
                                {serviceCharge.toFixed(2)}
                            </Body2>
                        </CardRow>
                        <CardRow>
                            <Body2>Gratuity ({proposal?.gratuity}%)</Body2>
                            <Body2>
                                {currencySymbol}
                                {gratuity.toFixed(2)}
                            </Body2>
                        </CardRow>
                        <CardRow>
                            <Body2 fontWeight={500}>Space Total</Body2>
                            <Body2 fontWeight={500}>
                                {currencySymbol}
                                {eventSpacesTotal.toFixed(2)}
                            </Body2>
                        </CardRow>
                    </Card>
                )}
            </Body>
            <Footer>
                <TotalCard>
                    <CardRow>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            Proposal Total
                        </Body2>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            $0.00
                        </Body2>
                    </CardRow>

                    {/* 
                    NOTE: Commented for now until the API is ready to handel deposit.
                    <CardRow>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            Deposit (0%)
                        </Body2>
                        <Body2 color={EColors.pureWhite} fontWeight={500}>
                            $0.00
                        </Body2>
                    </CardRow> 
                    */}
                </TotalCard>
                <InfoContainer>
                    <InfoOutlinedIcon fontSize="small" sx={{ color: getColor(EColors.bizlyOSWarning) }} />
                    <Body2>Complete all fields and assign a Colleague to send your proposal</Body2>
                </InfoContainer>
                <Divider />

                {/* Todo: Update this status to reference the constant from the proposal manager */}
                <Button
                    fullWidth
                    onClick={handleSendProposal}
                    disabled={isLoading || proposalStatus === 'Proposal Sent' || !hasAssignedContact}
                >
                    {`${proposalStatus === 'Proposal Sent' ? 'Proposal Sent' : 'Send Proposal'}`}
                </Button>
            </Footer>
        </Container>
    );
}
