import { InquiryAction } from './InquiryAction';
import InquiryOverviewBody from './InquiryOverviewBody/InquiryOverviewBody';

export default function InquiryOverview() {
    return (
        <>
            <InquiryOverviewBody />
            <InquiryAction />
        </>
    );
}
