import { TAccommodation, TEventSpace } from 'components/Planner/types';
import { isBefore } from 'date-fns';
import { i18n } from 'translation';
import { apiDateToDateObj, datetimeStrToLocalDate } from 'utils/date_util';

const renderError = (name: string, error: string) => (
    <span>
        {!!name && (
            <>
                <b>{`${name}:`}</b>
                <br />
            </>
        )}
        {`${error}.`}
    </span>
);

export const getEventSpaceValidationError = (eventSpaces?: TEventSpace[], isPlaybook?: boolean) => {
    const getEventSpaceError = ({ date, startTime, endTime, attendees, setupId, delete: esDeleted }: TEventSpace) => {
        if (esDeleted) {
            return undefined;
        }

        // Only validate these fields if not a playbook
        if (!isPlaybook) {
            if (!startTime) return i18n.venue.inquiry.error.startTimeRequired;
            if (!endTime) return i18n.venue.inquiry.error.endTimeRequired;
            if (!setupId) return i18n.venue.inquiry.error.setupRequired;
            if (!attendees) return i18n.venue.inquiry.error.attendeesRequired;
            if (!date) return i18n.venue.inquiry.error.dateRequired;
            const startDateObj = datetimeStrToLocalDate(date, startTime);
            if (isBefore(startDateObj, new Date())) return i18n.venue.inquiry.error.startDateInvalid;
        }
    };

    if (eventSpaces) {
        const badEventSpaceIdx = eventSpaces.findIndex(getEventSpaceError);
        const badEventSpace = badEventSpaceIdx !== -1 && eventSpaces[badEventSpaceIdx];

        if (badEventSpace) {
            const error = getEventSpaceError(badEventSpace);
            return renderError(badEventSpace.spaceName || i18n.common.meetingSpaceIndex(badEventSpaceIdx + 1), error!);
        }
    }
};

export const getAccommodationValidationError = (
    accommodations?: TAccommodation[],
    teamSettings?: Bizly.Team,
    isPlaybook?: boolean
) => {
    const getAccommodationError = ({ date, count, delete: deleted }: TAccommodation) => {
        if (deleted) {
            return undefined;
        }
        if (!isPlaybook) {
            if (!date) return i18n.venue.inquiry.error.dateRequired;
            const dateObj = apiDateToDateObj(date);
            if (isBefore(dateObj, new Date())) return i18n.venue.inquiry.error.dateInvalid;
        }

        if (teamSettings?.maxRoomsPerNightPerInquiry) {
            if (count && count > teamSettings.maxRoomsPerNightPerInquiry) {
                return i18n.venue.inquiry.error.exceedMaxRoomNumber(teamSettings.maxRoomsPerNightPerInquiry);
            }
        }
    };

    if (accommodations) {
        const badAccommodationIdx = accommodations.findIndex(getAccommodationError);
        const badAccommodation = badAccommodationIdx !== -1 && accommodations[badAccommodationIdx];

        if (badAccommodation) {
            const error = getAccommodationError(badAccommodation);
            return renderError(
                badAccommodation.roomName || i18n.common.guestRoomsIndex(badAccommodationIdx + 1),
                error!
            );
        }

        if (teamSettings?.maxTotalRoomNightsPerInquiry) {
            const totalCount = accommodations.reduce((acc, { count }) => acc + (count ?? 0), 0);
            if (totalCount > teamSettings.maxTotalRoomNightsPerInquiry) {
                return renderError(
                    '',
                    i18n.venue.inquiry.error.exceedTotalMaxRoomNumber(teamSettings.maxTotalRoomNightsPerInquiry)
                );
            }
        }
    }
};

export const getPlannerValidationError = (
    eventSpaces?: TEventSpace[],
    accommodations?: TAccommodation[],
    teamSettings?: Bizly.Team,
    isPlaybook?: boolean
) => {
    if ((!eventSpaces || eventSpaces.length === 0) && (!accommodations || accommodations.length === 0)) {
        return i18n.venue.inquiry.error.noBookings;
    }

    const eventSpaceError = getEventSpaceValidationError(eventSpaces, isPlaybook);
    if (eventSpaceError) {
        return eventSpaceError;
    }
    const accommodationError = getAccommodationValidationError(accommodations, teamSettings, isPlaybook);
    return accommodationError;
};
