import { getLoggedInUser } from 'api';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

interface User {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    imageUrl: string;
    role: string;
    title: string;
    createdAt: string;
    integrationReauthRequired: boolean;
    isSsoUser: boolean;
    isVenueUser: boolean;
    platformRole: string;
}

interface BizlyOSUserContextType {
    venueId: string;
    setVenueId: (venueId: string | null) => void;
    user: User | null;
    isLoading: boolean;
    error: unknown;
}

const BizlyOSUserContext = createContext<BizlyOSUserContextType | undefined>(undefined);

const queryClient = new QueryClient();

export const BizlyOSUserProvider = ({ children }: { children: React.ReactNode }) => {
    return (
        <QueryClientProvider client={queryClient}>
            <BizlyOSUserProviderInner>{children}</BizlyOSUserProviderInner>
        </QueryClientProvider>
    );
};

const BizlyOSUserProviderInner = ({ children }: { children: React.ReactNode }) => {
    const [currentVenueId, setCurrentVenueId] = useState<string>('');

    const setVenueId = useCallback((venueId: string | null) => {
        if (venueId) setCurrentVenueId(venueId);
    }, []);

    const {
        data: user,
        isLoading,
        error,
    } = useQuery<User, Error>({
        queryKey: ['user'],
        queryFn: getLoggedInUser,
    });

    const contextValue: BizlyOSUserContextType = {
        venueId: currentVenueId,
        setVenueId,
        user: user || null,
        isLoading,
        error,
    };

    return <BizlyOSUserContext.Provider value={contextValue}>{children}</BizlyOSUserContext.Provider>;
};

export const useBizlyOSUser = () => {
    const context = useContext(BizlyOSUserContext);
    if (context === undefined) {
        throw new Error('useBizlyOSUser must be used within a BizlyOSUserProvider');
    }
    return context;
};
