import { ProposalStatus } from '../InquiryAction';
import { DeclinedInquiryForm } from './DeclinedInquiryForm';
import { NewInquiry } from './NewInquiry';
import { RejectedInquiry } from './RejectedInquiry';

export const RenderInquiryContent = ({
    status,
    handleConfirmDecline,
    handleInitiateDecline,
    handleBack,
    venueId,
    proposalId,
}: {
    status: string;
    handleConfirmDecline: (data: { rejectionNotes: string; rejectionReason: string }) => void;
    handleInitiateDecline: () => void;
    handleBack: () => void;
    venueId: string;
    proposalId: string;
}) => {
    switch (status) {
        case ProposalStatus.REJECTED:
            return <RejectedInquiry />;

        case ProposalStatus.DECLINING:
            return <DeclinedInquiryForm onSubmit={handleConfirmDecline} onCancel={handleBack} />;

        case ProposalStatus.NEW_INQUIRY:
        default:
            return (
                <NewInquiry handleInitiateDecline={handleInitiateDecline} venueId={venueId} proposalId={proposalId} />
            );
    }
};
