import { Box, styled } from '@mui/material';
import InquiryRoutes from 'components/BizlyOS/Proposals/InquiryRoutes';
import InquirySubNavigation from 'components/BizlyOS/Proposals/InquirySubNavigation';
import { ProposalInquiryHeader } from 'components/BizlyOS/Proposals/ProposalInquryHeader/ProposalInquiryHeader';
import { Spinner } from 'components/Spinner';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useBizlyOSUser } from 'providers/bizly-os-user';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const Container = styled(Box)({
    height: '100%',
    maxHeight: '100%',
    boxSizing: 'border-box',
    overflowX: 'auto',
});

const HEADER_HEIGHT = '75px';

const HeaderContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    boxSizing: 'border-box',
    height: HEADER_HEIGHT,
    minWidth: '840px',
}));

const BodyContainer = styled(Box)(({ theme }) => ({
    height: `calc(100% - ${HEADER_HEIGHT})`,
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: 'minmax(180px,220px) minmax(540px,1fr) minmax(200px,360px)',
    gridTemplateRows: '1fr',
    gap: theme.spacing(2.5),
    padding: theme.spacing(2.5),
}));

export const ProposalInqury = () => {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);

    // We'll need another way to set the venueId from the Inquiries page
    const { setVenueId } = useBizlyOSUser();

    const safeVenueId = useMemo(() => venueId ?? '', [venueId]);

    useEffect(() => {
        if (safeVenueId) {
            setVenueId(safeVenueId);
        }
    }, [safeVenueId, setVenueId]);

    if (isLoading || !proposalInquiry) {
        return (
            <Box p={10} display="flex" alignItems="center" justifyContent="center">
                <Spinner />
            </Box>
        );
    }

    return (
        <Container>
            <HeaderContainer>
                <ProposalInquiryHeader proposalInquiry={proposalInquiry} />
            </HeaderContainer>
            <BodyContainer>
                <InquirySubNavigation />
                <InquiryRoutes />
            </BodyContainer>
        </Container>
    );
};
