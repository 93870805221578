import CurrencyField from 'components/BizlyOS/InputFields/CurrencyField';
import InputField from 'components/BizlyOS/InputFields/InputField';
import RadioGroup from 'components/BizlyOS/InputFields/RadioGroup';
import { CURRENCY_MODAL_DESCRIPTION } from 'components/BizlyOS/Proposals/utils';
import { Controller } from 'react-hook-form';
import { BorderedContainer, Description, EventSpaceFormProps, Row, Title } from './utils';

export function Pricing({ control, errors, currencyCode, useDdr, proposalInquiry, setValue }: EventSpaceFormProps) {
    return (
        <BorderedContainer>
            <Title>Pricing</Title>
            <Description>Provide prices below.</Description>

            <Controller
                name="useDdr"
                control={control}
                render={({ field, fieldState }) => {
                    return (
                        <RadioGroup
                            {...field}
                            label="Rate Type"
                            onChange={e => {
                                field.onChange(e === 'true');
                            }}
                            columns={2}
                            error={fieldState.error?.message || ''}
                            options={[
                                { label: 'Daily Room Rate', value: false },
                                { label: 'Per Person Rate', value: true },
                            ]}
                        />
                    );
                }}
            />

            <Row sx={{ marginTop: 2.5 }}>
                {useDdr && (
                    <>
                        <Controller
                            name="proposedRatePerPerson"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <CurrencyField
                                        {...field}
                                        type="number"
                                        required
                                        currencyCode={currencyCode}
                                        onCurrencyCodeChange={code =>
                                            setValue('currencyCode', code, { shouldValidate: true, shouldDirty: true })
                                        }
                                        error={errors.proposedRatePerPerson}
                                        label="Per Person Rate"
                                        currencyModalDescription={CURRENCY_MODAL_DESCRIPTION}
                                        currencyOptions={proposalInquiry?.options.currencies || []}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="proposedMinGuests"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <InputField
                                        {...field}
                                        label="Minimum Number of Guests"
                                        type="number"
                                        required
                                        error={errors.proposedMinGuests}
                                    />
                                );
                            }}
                        />
                    </>
                )}

                {!useDdr && (
                    <>
                        <Controller
                            name="proposedRoomRate"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <CurrencyField
                                        {...field}
                                        type="number"
                                        required
                                        currencyCode={currencyCode}
                                        onCurrencyCodeChange={code =>
                                            setValue('currencyCode', code, { shouldValidate: true, shouldDirty: true })
                                        }
                                        error={errors.proposedRoomRate}
                                        label="Daily Room Rate"
                                        currencyModalDescription={CURRENCY_MODAL_DESCRIPTION}
                                        currencyOptions={proposalInquiry?.options.currencies || []}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="proposedFbMinimum"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <CurrencyField
                                        {...field}
                                        type="number"
                                        required
                                        currencyCode={currencyCode}
                                        onCurrencyCodeChange={code =>
                                            setValue('currencyCode', code, { shouldValidate: true, shouldDirty: true })
                                        }
                                        error={errors.proposedFbMinimum}
                                        label="Daily F&B Minimum"
                                        currencyModalDescription={CURRENCY_MODAL_DESCRIPTION}
                                        currencyOptions={proposalInquiry?.options.currencies || []}
                                    />
                                );
                            }}
                        />
                    </>
                )}
            </Row>
        </BorderedContainer>
    );
}
