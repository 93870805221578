import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { EColors } from 'theme';
import { Body2, Caption } from '../Typography/Typography';

const StyledSelect = styled(Select, {
    shouldForwardProp: prop => prop !== 'disabled' && prop !== 'error',
})<{ disabled?: boolean }>(({ theme: { getColor, EColors, shape }, disabled, error }) => ({
    height: '50px',
    width: '100%',
    fontSize: '0.875rem',
    backgroundColor: disabled ? getColor(EColors.drWhite) : getColor(EColors.pureWhite),
    pointerEvents: disabled ? 'none' : 'auto',

    '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${error ? getColor(EColors.red) : getColor(EColors.bizlyOSBorder)}`,
        borderRadius: shape.borderRadius,
    },

    '&.Mui-disabled': {
        color: getColor(EColors.pureBlack, 0.5),
        opacity: 0.5,

        '& .MuiSelect-select': {
            color: getColor(EColors.pureBlack),
            '-webkit-text-fill-color': getColor(EColors.pureBlack),
        },

        '& .MuiMenuItem-root.Mui-disabled': {
            color: getColor(EColors.pureBlack),
        },
    },
}));

const StyledMenuItem = styled(MenuItem)<{ disabled?: boolean }>({
    fontSize: '0.875rem',
});

const StyledFormControl = styled(FormControl)<{ disabled?: boolean }>(({ theme: { getColor, EColors }, disabled }) => ({
    width: '100%',
    backgroundColor: disabled ? getColor(EColors.drWhite) : getColor(EColors.pureWhite),
    borderRadius: '4px',
    pointerEvents: disabled ? 'none' : 'auto',
}));

const StyledInputLabel = styled(InputLabel)(({ theme: { getColor, EColors } }) => ({
    fontSize: '0.875rem',
    lineHeight: '0.875rem',

    '&.MuiFormLabel-root': {
        top: '2px',
        color: getColor(EColors.pureBlack, 0.5),
    },

    '&.Mui-focused': {
        color: getColor(EColors.pureBlack),
    },

    '&.Mui-disabled': {
        color: `${getColor(EColors.pureBlack, 0.5)}`,
    },
}));

type SelectFieldProps = {
    value: string | number | undefined;
    options: { value: number | string; label: string; icon?: React.ReactElement }[];
    label?: string;
    placeholder?: string;
    emptyMessage?: string;
    error?: FieldError | undefined;
    disabled?: boolean;
    required?: boolean;
    name?: string;
    notSelectedValue?: string | number;
    size?: 'large' | 'small';
    onChange?: (event: SelectChangeEvent<string | number>) => void;
    onBlur?: () => void;
};

const SelectField = React.forwardRef<HTMLDivElement, SelectFieldProps>(
    (
        {
            options = [],
            placeholder,
            label,
            error,
            onChange,
            emptyMessage = '',
            disabled = false,
            required = false,
            notSelectedValue,
            size = 'small',
            ...field
        },
        ref
    ) => {
        return (
            <Box>
                {label && (
                    <Box display="flex" gap={0.5} marginBottom={1}>
                        <Body2 fontWeight={500}>{label}</Body2>
                        {required && (
                            <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                                *
                            </Body2>
                        )}
                    </Box>
                )}

                <StyledFormControl fullWidth disabled={disabled}>
                    {(!field.value || field.value === notSelectedValue) && (
                        <StyledInputLabel shrink={false}>{placeholder ? placeholder : 'Select'}</StyledInputLabel>
                    )}
                    <StyledSelect
                        IconComponent={KeyboardArrowDownIcon}
                        onChange={onChange}
                        {...field}
                        ref={ref}
                        disabled={disabled}
                        error={!!error}
                        renderValue={(value: string) => {
                            const option = options.find(o => o.value === value);
                            return (
                                <Box display="flex" alignItems="center" gap={1.25}>
                                    {option?.icon}
                                    <Body2 size={size}>{option?.label}</Body2>
                                </Box>
                            );
                        }}
                    >
                        {options.map(option => (
                            <StyledMenuItem key={option.value} value={option.value}>
                                <Box display="flex" alignItems="center" gap={1.25}>
                                    {option?.icon}
                                    {option?.label}
                                </Box>
                            </StyledMenuItem>
                        ))}

                        {options.length === 0 && !!emptyMessage ? (
                            <StyledMenuItem disabled>{emptyMessage}</StyledMenuItem>
                        ) : null}
                    </StyledSelect>
                </StyledFormControl>
                {!!error && (
                    <Caption size="large" color={EColors.red} fontWeight={500}>
                        {error.message}
                    </Caption>
                )}
            </Box>
        );
    }
);

export default SelectField;
