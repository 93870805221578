import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import IataIcon from 'images/bizlyOS/icons/iataIcon.svg';

const BorderedContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
}));

const InfoContainer = styled(BorderedContainer)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: spacing(1.25),
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    border: 'none',
}));

export function CommissionDetails({ documentUrl }: { documentUrl: string }) {
    return (
        <InfoContainer>
            <img src={IataIcon} alt="IATA Icon" />
            <Box flex={1}>
                <Body1 fontWeight={600}>Commissionable Event</Body1>
                <Body2 fontWeight={600}>
                    IATA Number: <span style={{ fontWeight: '400' }}>33-7 4226 2</span>
                </Body2>
            </Box>
            <a href={documentUrl} download>
                <Button variant="text" sx={{ padding: 0 }}>
                    Terms
                </Button>
            </a>
        </InfoContainer>
    );
}
