import { useSnackbar, VariantType } from 'notistack';

export function useBizlySnackbar() {
    const { enqueueSnackbar } = useSnackbar();

    const bizlyOsSnackbar = (message: string, variant: VariantType = 'info') => {
        enqueueSnackbar(message, {
            variant,
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
    };

    const bizlyOSErrorSnackbar = (error?: unknown) => {
        let errorMessage = 'Something went wrong. Please try again';
        if (typeof error === 'string') errorMessage = error;
        if (error instanceof Error) errorMessage = error.message;
        enqueueSnackbar(errorMessage, { variant: 'error', anchorOrigin: { horizontal: 'center', vertical: 'top' } });
    };

    return { bizlyOsSnackbar, bizlyOSErrorSnackbar };
}
