import { Box } from '@mui/material';
// import { Button } from 'components/BizlyOS/Button/Button';
import { TitleTab, TitleTabs } from 'components/BizlyOS/Tabs';
import TitledContainer from 'components/BizlyOS/TitledContainer';
import React, { useState } from 'react';
import { EColors, getColor } from 'theme';
import { BasicInfoBox } from '../../../InfoBoxes';
import { SideDrawer } from '../../../SideDrawer/SideDrawer';
import { ActiveUsers } from './ActiveUsers';

// import { PendingInvites } from './PendingInvites';

const TabOptions = [
    { label: 'Active Users', Element: ActiveUsers, description: 'Assign to yourself or delegate to a colleague.' },
];

type AssignInquirySideDrawerProps = {
    drawerOpen: boolean;
    onClose: () => void;
    // onInviteColleagueClicked: () => void;
};

export function AssignInquirySideDrawer({
    drawerOpen,
    onClose,
    // onInviteColleagueClicked,
}: AssignInquirySideDrawerProps) {
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (_: React.SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex);
    };

    return (
        <SideDrawer drawerOpen={drawerOpen} onClose={onClose} title="Assign Inquiry">
            <Box display="flex" flexDirection="column" gap={2.5}>
                <BasicInfoBox color={getColor(EColors.primaryAction)} message={TabOptions[tabIndex].description} />
                <TitledContainer
                    borderWidth="small"
                    fullBodyHeight
                    bodyPadding="0px"
                    leftElement={
                        <TitleTabs value={tabIndex} onChange={handleTabChange}>
                            {TabOptions.map((tab, index) => (
                                <TitleTab key={index} label={tab.label} value={index} />
                            ))}
                        </TitleTabs>
                    }
                    // rightElement={
                    //     <Button
                    //         variant="text"
                    //         onClick={onInviteColleagueClicked}
                    //         sx={{ position: 'absolute', right: '8px' }}
                    //     >
                    //         + Invite Colleague
                    //     </Button>
                    // }
                >
                    {React.createElement(TabOptions[tabIndex].Element)}
                </TitledContainer>
            </Box>
        </SideDrawer>
    );
}
