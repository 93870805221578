import { Box } from '@mui/material';
import { BasicInfoBox } from 'components/BizlyOS/InfoBoxes';
import { hasEventSpaceBeenFilled } from 'components/BizlyOS/Proposals/utils';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import EventSpacesAccordion from './EventSpacesAccordion';
import { EventSpacesCount } from './EventSpacesCount';

export function EventSpaces() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);

    const eventSpaces = proposalInquiry?.eventSpaces || [];
    const proposal = proposalInquiry?.proposal;
    const options = proposalInquiry?.options;

    const [accordionExpands, setAccordionExpands] = useState<number[]>([]);

    const onAccordionExpandChange = (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        setAccordionExpands(prev => {
            if (newExpanded) {
                return [...prev, panel];
            }
            return prev.filter(index => index !== panel);
        });
    };

    if (isLoading || !eventSpaces) return null;

    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            <BasicInfoBox color={getColor(EColors.primaryAction)} message="Add or edit requested spaces." />

            <EventSpacesCount count={eventSpaces?.length || 0} />

            {eventSpaces.map((eventSpace, index) => {
                const isFilled = hasEventSpaceBeenFilled(eventSpace, Boolean(proposal?.useDdr));
                return (
                    <EventSpacesAccordion
                        key={eventSpace.inquirySpaceId}
                        index={index}
                        eventSpace={eventSpace}
                        expanded={accordionExpands.includes(index)}
                        onExpandChange={onAccordionExpandChange}
                        options={options}
                        isFilled={isFilled}
                    />
                );
            })}
        </Box>
    );
}
