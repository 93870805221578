import { zodResolver } from '@hookform/resolvers/zod';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { Box } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import DatePicker from 'components/BizlyOS/InputFields/DatePicker';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import {
    useGetProposalInquiry,
    useSendProposal,
    useUpdateProposalInquiry,
} from 'hooks/queries/BizlyOS/useProposalsQuery';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';
import { z } from 'zod';
import { DATE_FORMAT } from '../../utils';

const ExtensionSchema = z.object({
    expirationDate: z.string().min(1, 'Required'),
});

type ExtensionFormData = z.infer<typeof ExtensionSchema>;

export const InitiateExtendProposal = ({
    cancelExtendProposal,
    confirmExtendProposal,
}: {
    cancelExtendProposal: () => void;
    confirmExtendProposal: () => void;
}) => {
    const { venueId = '', proposalId = '' } = useParams<{ venueId?: string; proposalId?: string }>();

    const { data: proposalInquiry } = useGetProposalInquiry(venueId, proposalId);

    const updateProposalInquiry = useUpdateProposalInquiry(venueId, proposalId);
    const sendProposal = useSendProposal(venueId, proposalId);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm<ExtensionFormData>({
        resolver: zodResolver(ExtensionSchema),
        defaultValues: {
            expirationDate: '',
        },
        mode: 'onChange',
    });

    const onSubmit = (data: ExtensionFormData) => {
        if (isValid && proposalInquiry?.proposal) {
            const extendedProposal = {
                ...proposalInquiry,
                proposal: {
                    ...proposalInquiry.proposal,
                    expiryDate: data.expirationDate,
                },
            };

            updateProposalInquiry.mutate(extendedProposal, {
                onSuccess: () => {
                    sendProposal.mutate();
                    confirmExtendProposal();
                },
            });
        }
    };

    return (
        <>
            <Box display="flex" flexDirection="row" gap={1.25} marginBottom={1.25}>
                <RequestQuoteOutlinedIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                <Body1 fontWeight={600}>Proposal Expired</Body1>
            </Box>
            <Body2 marginBottom={2.5}>
                Your proposal expired {tzMoment(proposalInquiry?.proposal?.expiryDate).format(DATE_FORMAT)}. We highly
                suggest you extend if you still have availability.
            </Body2>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="expirationDate"
                    control={control}
                    render={({ field }) => (
                        <DatePicker
                            {...field}
                            required
                            error={errors.expirationDate}
                            value={field.value}
                            disablePast
                            iconColor={getColor(EColors.pureBlack)}
                        />
                    )}
                />
                <Button
                    fullWidth
                    size="small"
                    sx={{ marginTop: '20px', marginBottom: '10px', height: '40px' }}
                    type="submit"
                    disabled={isSubmitting || !isValid}
                >
                    Extend Proposal
                </Button>
            </Box>
            <Button fullWidth variant="outline" sx={{ height: 40 }} onClick={cancelExtendProposal}>
                Go Back
            </Button>
        </>
    );
};
