import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { Box } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';

export const ProposalExpired = ({
    initiateExtendProposal,
    startWithdrawProcess,
}: {
    initiateExtendProposal: () => void;
    startWithdrawProcess: () => void;
}) => {
    const { venueId = '', proposalId = '' } = useParams<{ venueId?: string; proposalId?: string }>();

    const { data: proposalInquiry } = useGetProposalInquiry(venueId, proposalId);

    return (
        <>
            <Box display="flex" flexDirection="row" gap={1.25} marginBottom={1.25}>
                <RequestQuoteOutlinedIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                <Body1 fontWeight={600}>Proposal Expired</Body1>
            </Box>
            <Body2 marginBottom={2.5}>
                Your proposal expired {proposalInquiry?.proposal.expiryDate}. We highly suggest you extend if you still
                have availability.
            </Body2>
            <Button
                fullWidth
                size="small"
                sx={{ marginBottom: '10px', height: '40px' }}
                onClick={initiateExtendProposal}
            >
                Extend Proposal
            </Button>
            <Button fullWidth variant="outline" color="error" sx={{ height: '40px' }} onClick={startWithdrawProcess}>
                Withdraw Proposal
            </Button>
        </>
    );
};
