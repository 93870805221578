import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { EColors, getColor } from 'theme';
import { BasicInfoBox } from '../InfoBoxes';
import { AddAnotherButton } from './AddAnotherButton';

export function DrawerHeader({
    goBackEnabled,
    isEdit,
    addMultiple,
    handleAddAnother,
}: {
    goBackEnabled: boolean;
    isEdit: boolean;
    addMultiple: boolean;
    handleAddAnother: () => void;
}) {
    if (isEdit && !addMultiple) return;

    return (
        <>
            {goBackEnabled && (
                <BasicInfoBox
                    icon={<InfoOutlinedIcon fontSize="small" sx={{ color: getColor(EColors.primaryAction) }} />}
                    message="Invite colleagues to help you manage your Bizly account and inquiries."
                />
            )}

            {addMultiple && <AddAnotherButton onClick={handleAddAnother} />}
        </>
    );
}
