import CancelIcon from '@mui/icons-material/Cancel';
import { Box } from '@mui/material';
import RichTextEditorField from 'components/BizlyOS/InputFields/RichTextEditorField';
import SelectField from 'components/BizlyOS/InputFields/SelectField';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { tzMoment } from 'utils/moment';

export const RejectedInquiry = () => {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry } = useGetProposalInquiry(venueId, proposalId);

    const { inquiry, client } = proposalInquiry || {};

    return (
        <Box display="flex" flexDirection="column" gap={2.5}>
            {/* Title Section */}
            <Box display="flex" flexDirection="row" gap={1.25}>
                <CancelIcon sx={{ color: getColor(EColors.warningText) }} />
                <Body1 fontWeight={600} sx={{ color: getColor(EColors.warningText) }}>
                    Inquiry Declined
                </Body1>
            </Box>
            <Body2>
                {`${client?.firstName} ${client?.lastName} declined the inquiry on 
                ${tzMoment(inquiry?.rejectedAt?.date).format('MMM D, YYYY')}.`}
            </Body2>

            {/* Reason Dropdown */}
            <SelectField
                label="Reason"
                placeholder="Select a reason"
                options={[{ value: inquiry?.rejectionReason ?? '', label: inquiry?.rejectionReason ?? '' }]}
                value={inquiry?.rejectionReason ?? ''}
                disabled
            />

            {/* Rich Text Message */}
            <RichTextEditorField
                value={inquiry?.rejectionNotes ?? ''}
                placeholder="Write..."
                label="Message to client"
                disabled
            />
        </Box>
    );
};
