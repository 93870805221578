import { zodResolver } from '@hookform/resolvers/zod';
import InfoIcon from '@mui/icons-material/Info';
import {
    Box,
    Button,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Link,
    MenuItem,
    Popover,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@mui/material';
import { RichTextDisplay } from 'components/Form/RichTextEditor';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { H2Headline } from 'components/ui/Headline';
import fontFns from 'fontFns';
import ConciergeIcon from 'images/icons/concierge.svg?react';
import { useUser } from 'providers/user';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { i18n } from 'translation';
import { InlineRow } from 'ui';
import * as z from 'zod';
import { VerbiageAlert } from '../Steps/styled';
import { AppleEventForm, SSP_OPT_OUT_REASONS } from '../formSchema';

const Step2Schema = z
    .object({
        cventId: z.string().min(1, { message: 'Cvent ID is required' }).optional(),
        costCenter: z.string().min(1, { message: 'Cost center is required' }).optional(),
        department: z.string().min(1, { message: 'Department is required' }).optional(),
        budget: z.string().optional(),
        type: z.enum(['', 'Internal', 'External']).optional(),
        recordId: z.string().min(1, { message: 'Record ID is required' }).optional(),
        useSsp: z.boolean({
            required_error: 'Please select whether you want to use Self-Service Plus',
            invalid_type_error: 'Please select whether you want to use Self-Service Plus',
        }),
        sspOptOutReason: z.string().nullable(),
        sspOptOutOtherReason: z.string().nullable(),
    })
    .superRefine((data, ctx) => {
        if (data.useSsp === false) {
            if (!data.sspOptOutReason || data.sspOptOutReason === '') {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Please select a reason for not using Self-Service Plus',
                    path: ['sspOptOutReason'],
                });
            }

            if (data.sspOptOutReason === 'Other' && (!data.sspOptOutOtherReason || data.sspOptOutOtherReason === '')) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Please specify your reason',
                    path: ['sspOptOutOtherReason'],
                });
            }
        }
    });

export type Step2FormData = z.infer<typeof Step2Schema>;

const MediumDialogContent = styled(DialogContent)`
    width: 20rem;
    padding: 2rem;
`;

const FormTextFieldLabel = styled(InlineRow)`
    font-size: 1em;
    line-height: 1.25em;
    font-weight: 500;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
    margin-bottom: 0.5em;
`;

const FieldOptionalLabel = styled.span`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.optionalSpecifier)};
    margin-left: 5px;
`;

const SelfServiceBox = styled(Box)`
    border: 1px solid;
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.inactiveAction)};
    border-radius: 0.5em;
    padding: 1.25em;
`;

const SSPOptionsContainer = styled(Box)`
    margin-top: 1em;
`;

const OptOutContainer = styled(Box)`
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
`;

export const AppleEventCreateStep2 = ({
    formData,
    onSubmit,
    handleBack,
}: {
    formData: AppleEventForm;
    onSubmit: (data: Step2FormData) => void;
    handleBack: () => void;
}) => {
    const { user } = useUser();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm<Step2FormData>({
        resolver: zodResolver(Step2Schema),
        defaultValues: { ...formData },
    });

    const useSsp = watch('useSsp');
    const sspOptOutReason = watch('sspOptOutReason');
    const sspOptOutOtherReason = watch('sspOptOutOtherReason');

    const handleStep2Submit: SubmitHandler<Step2FormData> = data => {
        onSubmit(data);
    };

    const getSspMessage = (sspEventsRemaining: number | undefined) => {
        if (sspEventsRemaining === undefined) {
            return '';
        }
        if (sspEventsRemaining > 1) {
            return `You have ${sspEventsRemaining} Self-Service Plus meetings left. The Bizly Concierge will help you negotiate pricing, and walk you through the contracting process. Use one for this meeting?`;
        }
        if (sspEventsRemaining === 1) {
            return 'You have 1 Self-Service Plus meeting left. The Bizly Concierge will help you negotiate pricing and the contracting process. Use it for this meeting?';
        }
        return 'No Self-Service Plus meeting left. Chat with our Support Team if you have any questions.';
    };

    return (
        <Box>
            <H2Headline>{i18n.proposalForm.proposalNotes.additionalDetails}</H2Headline>
            {user.team?.authMeetingCreateFields && user.team.authMeetingCreateRedirect && (
                <VerbiageAlert severity="info" icon={<InfoIcon fontSize="inherit" />}>
                    {i18n.homepage.createMeetingModal.teamEventProtocol(user.team.name)}{' '}
                    <Link onClick={e => setAnchorEl(e.currentTarget)} style={{ cursor: 'pointer' }}>
                        {i18n.homepage.createMeetingModal.learnMore}
                    </Link>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <MediumDialogContent>
                            <RichTextDisplay value={user.team.authMeetingCreateRedirect} />
                        </MediumDialogContent>
                    </Popover>
                </VerbiageAlert>
            )}

            <form onSubmit={handleSubmit(handleStep2Submit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="cventId"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.meetingId}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.cventId}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="recordId"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.recordId}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.recordId}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="costCenter"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.costCenter}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.costCenter}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="department"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.department}
                                    </FormTextFieldLabel>
                                    <TextField {...field} variant="outlined" fullWidth error={!!errors.department} />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="budget"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.budget}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.budget}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.meetingType}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <FormControl fullWidth error={!!errors.type}>
                                        <Select {...field} defaultValue="">
                                            <MenuItem value="">{i18n.homepage.createMeetingModal.none}</MenuItem>
                                            <MenuItem value="Internal">
                                                {i18n.meetingDashboard.headerSection.settings.internal}
                                            </MenuItem>
                                            <MenuItem value="External">
                                                {i18n.meetingDashboard.headerSection.settings.external}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </>
                            )}
                        />
                    </Grid>

                    {user.ssp?.enabled && (
                        <Grid item sm={12}>
                            <SelfServiceBox>
                                <Box display="flex" gap={1.25}>
                                    <Box>
                                        <ConciergeIcon />
                                    </Box>
                                    <Box>{getSspMessage(user.ssp?.sspEventsRemaining)}</Box>
                                </Box>

                                {typeof user.ssp.sspEventsRemaining === 'number' && user.ssp.sspEventsRemaining > 0 && (
                                    <SSPOptionsContainer>
                                        <FormControl error={!!errors.useSsp}>
                                            <Controller
                                                name="useSsp"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup
                                                        {...field}
                                                        value={field.value}
                                                        onChange={e => field.onChange(e.target.value === 'true')}
                                                    >
                                                        <FormControlLabel
                                                            value={true}
                                                            control={<Radio />}
                                                            label="Yes, I want to use Self-Service Plus for this meeting"
                                                        />
                                                        <FormControlLabel
                                                            value={false}
                                                            control={<Radio />}
                                                            label="No, I don't need Self-Service Plus for this meeting"
                                                        />
                                                    </RadioGroup>
                                                )}
                                            />
                                            {errors.useSsp && <FormHelperText>{errors.useSsp.message}</FormHelperText>}
                                        </FormControl>

                                        {useSsp === false && (
                                            <OptOutContainer>
                                                <FormControl fullWidth error={!!errors.sspOptOutReason}>
                                                    <FormTextFieldLabel>
                                                        Please select a reason for not using Self-Service Plus
                                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                                    </FormTextFieldLabel>
                                                    <Select
                                                        value={sspOptOutReason || ''}
                                                        onChange={e => {
                                                            setValue('sspOptOutReason', e.target.value);
                                                            if (e.target.value !== 'Other') {
                                                                setValue('sspOptOutOtherReason', null);
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem value="">
                                                            <em>Select a reason</em>
                                                        </MenuItem>
                                                        {SSP_OPT_OUT_REASONS.map(reason => (
                                                            <MenuItem key={reason} value={reason}>
                                                                {reason}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.sspOptOutReason && (
                                                        <FormHelperText>
                                                            {errors.sspOptOutReason.message}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                {sspOptOutReason === 'Other' && (
                                                    <>
                                                        <Spacer xsmall />
                                                        <TextField
                                                            fullWidth
                                                            placeholder="Please specify your reason"
                                                            value={sspOptOutOtherReason || ''}
                                                            onChange={e =>
                                                                setValue('sspOptOutOtherReason', e.target.value)
                                                            }
                                                            error={!!errors.sspOptOutOtherReason}
                                                            helperText={errors.sspOptOutOtherReason?.message}
                                                        />
                                                    </>
                                                )}
                                            </OptOutContainer>
                                        )}
                                    </SSPOptionsContainer>
                                )}
                            </SelfServiceBox>
                        </Grid>
                    )}
                </Grid>

                <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
                    <Button variant="text" onClick={handleBack}>
                        {i18n.button.back}
                    </Button>
                    <Button type="submit" variant="contained">
                        {i18n.button.create}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};
