import { Box, styled } from '@mui/material';
import { postProposalFormContact } from 'api/proposalForms';
import { PROPOSAL_QUERIES, useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useStaffQuery } from 'hooks/queries/BizlyOS/useStaffQuery';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { UserItem } from './UserItem';

const Container = styled(Box)({
    '& > :last-child': {
        borderBottom: 'none',
    },
});

export const ActiveUsers = () => {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { staffMembers } = useStaffQuery(venueId);
    const { data: proposalInquiry } = useGetProposalInquiry(venueId, proposalId);
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const handleAssignClicked = async (id: number) => {
        const selectedStaff = staffMembers?.find(staff => staff.id === id);

        try {
            await postProposalFormContact(venueId, proposalId, selectedStaff);
            await queryClient.invalidateQueries([PROPOSAL_QUERIES.PROPOSAL_INQURY, venueId, proposalId]);

            enqueueSnackbar('User assigned successfully to proposal', { variant: 'success' });
        } catch (error) {
            console.error('Assignment failed:', error);
            enqueueSnackbar('Assignment failed', { variant: 'error' });
        }
    };

    return (
        <Container>
            {staffMembers?.map(staff => (
                <UserItem
                    key={staff.id}
                    id={Number(staff.id)}
                    firstName={staff.firstName}
                    lastName={staff.lastName}
                    email={staff.email}
                    isAssigned={staff.id === proposalInquiry?.contact?.id}
                    avatarUrl={staff?.imageUrl || ''}
                    onAssignClicked={handleAssignClicked}
                />
            ))}
        </Container>
    );
};
