import { styled, TabProps, TabsProps } from '@mui/material';
import { SecondaryTab, SecondaryTabs } from 'components/BizlyOS/Tabs';
import TitledContainer from 'components/BizlyOS/TitledContainer';
import { Spinner } from 'components/Spinner';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import React, { Suspense, useState } from 'react';
import { useParams } from 'react-router-dom';

const OVERVIEW_ID = 'overview';
const ROOMBLOCK_ID = 'room-block';
const EVENT_SPACE_ID = 'event-space';
const IMPORTANT_DETALS_ID = 'important-detals';

const TabOptions = [
    {
        id: OVERVIEW_ID,
        label: 'Overview',
        Element: React.lazy(() => import('./Overview')),
    },
    {
        id: ROOMBLOCK_ID,
        label: 'Room Block',
        Element: React.lazy(() => import('./RoomBlock')),
    },
    {
        id: EVENT_SPACE_ID,
        label: 'Meeting / Event Spaces',
        Element: React.lazy(() => import('./EventSpaces')),
    },
    {
        id: IMPORTANT_DETALS_ID,
        label: 'Important Details',
        Element: React.lazy(() => import('./ImportantDetails')),
    },
];

const StyledTabs = styled(SecondaryTabs)<TabsProps>(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.drWhite),
    padding: 0,
    '&.MuiTabs-root': {
        minHeight: 'unset',
        borderBottom: 'none',
    },
}));

const StyledTab = styled(SecondaryTab)<TabProps>({
    '&.MuiTab-root': {
        minHeight: '38px',
        padding: '0px 0px',
    },
});

export default function InquiryOverviewBody() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry } = useGetProposalInquiry(venueId, proposalId);

    const { proposal, inquiry, guestRooms = [], eventSpaces = [] } = proposalInquiry || {};

    const [tabIndex, setTabIndex] = useState(0);

    const visibleTabIds = [
        OVERVIEW_ID,
        ...(eventSpaces?.length ? [EVENT_SPACE_ID] : []),
        ...(guestRooms?.length ? [ROOMBLOCK_ID] : []),
        ...(proposal?.msaDocumentUrl || inquiry?.notes ? [IMPORTANT_DETALS_ID] : []),
    ];

    const handleTabChange = (_: React.SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex);
    };

    return (
        <TitledContainer
            borderWidth="small"
            leftElement={
                <StyledTabs value={tabIndex} onChange={handleTabChange}>
                    {TabOptions.map(
                        (tab, index) =>
                            visibleTabIds.includes(tab.id) && <StyledTab key={index} label={tab.label} value={index} />
                    )}
                </StyledTabs>
            }
        >
            <Suspense fallback={<Spinner />}>{React.createElement(TabOptions[tabIndex].Element)}</Suspense>
        </TitledContainer>
    );
}
