import { TitleTab, TitleTabs } from 'components/BizlyOS/Tabs';
import TitledContainer from 'components/BizlyOS/TitledContainer';
import { Spinner } from 'components/Spinner';
import { useGetProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FIRST_INDEX, SECOND_INDEX, THIRD_INDEX } from '../../utils';
import { Commissions } from './Commissions';
import { EventSpaces } from './EventSpaces/EventSpaces';
import { FinalDetails } from './FinalDetails';
import { RoomBlock } from './RoomBlock/RoomBlock';
import { TaxAndFees } from './TaxAndFees';

const ROOMBLOCK_ID = 'room-block';
const EVENT_SPACE_ID = 'event-space';
const TAX_AND_FEES_ID = 'tax-and-fees';
const COMMISSIONS_ID = 'commissions';
// const TERMS_ID = 'terms';
const FINAL_DETAILS_ID = 'final-details';

const TabOptions = [
    { id: ROOMBLOCK_ID, label: 'Room Block', Element: RoomBlock },
    { id: EVENT_SPACE_ID, label: 'Spaces', Element: EventSpaces },
    { id: TAX_AND_FEES_ID, label: 'Taxes & Fees', Element: TaxAndFees },
    { id: COMMISSIONS_ID, label: 'Commissions', Element: Commissions },
    // NOTE: This will be hidden until the API is ready
    // { id: TERMS_ID, label: 'Terms', Element: Terms },
    { id: FINAL_DETAILS_ID, label: 'Final Details', Element: FinalDetails },
];

const allowedTabs = [ROOMBLOCK_ID, EVENT_SPACE_ID, TAX_AND_FEES_ID, COMMISSIONS_ID, FINAL_DETAILS_ID];

export function InquiryProposalBody() {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);

    const guestRooms = proposalInquiry?.guestRooms || [];
    const eventSpaces = proposalInquiry?.eventSpaces || [];

    const defaultTabIndex = guestRooms?.length ? FIRST_INDEX : eventSpaces?.length ? SECOND_INDEX : THIRD_INDEX;

    const [tabIndex, setTabIndex] = useState(defaultTabIndex);

    const visibleTabIds = [
        TAX_AND_FEES_ID,
        FINAL_DETAILS_ID,
        COMMISSIONS_ID,
        ...(eventSpaces?.length ? [EVENT_SPACE_ID] : []),
        ...(guestRooms?.length ? [ROOMBLOCK_ID] : []),
    ];

    const handleTabChange = (_: React.SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex);
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <TitledContainer
            borderWidth="small"
            leftElement={
                <TitleTabs value={tabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons={false}>
                    {TabOptions.map(
                        (tab, index) =>
                            visibleTabIds.includes(tab.id) && (
                                <TitleTab
                                    disabled={!allowedTabs.includes(tab.id)}
                                    key={index}
                                    label={tab.label}
                                    value={index}
                                />
                            )
                    )}
                </TitleTabs>
            }
        >
            {React.createElement(TabOptions[tabIndex].Element)}
        </TitledContainer>
    );
}
