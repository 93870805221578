import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { Avatar, Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { Body2, Caption } from 'components/BizlyOS/Typography/Typography';
import { EColors, getColor } from 'theme';

const ListItem = styled(Box)(({ theme: { spacing, getColor, EColors } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1.25),
    padding: spacing(1.875, 2.5),
    borderBottom: `1px solid ${getColor(EColors.bizlyOSBorder)}`,
}));

const DisabledButton = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(0.5),
    cursor: 'not-allowed',
}));

type UserItemProps = {
    id: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    isAssigned?: boolean;
    avatarUrl?: string;
    disabled?: boolean;
    onAssignClicked?: (id: number) => void;
};

export function UserItem({
    id,
    firstName,
    lastName,
    email,
    isAssigned,
    avatarUrl,
    disabled = false,
    onAssignClicked,
}: UserItemProps) {
    return (
        <ListItem>
            {/* NOTE: This is a temporary fix to the avatar url because the default avatar url needs to be updated. */}
            <Avatar
                src={avatarUrl?.includes('default-avatar.png') ? '' : avatarUrl}
                alt={firstName + ' ' + lastName}
                sx={{ width: '50px', height: '50px' }}
            />

            <Box flex={1}>
                <Body2 fontWeight={500}>
                    {firstName} {lastName}
                </Body2>
                <Caption size="large" lineHeight="21px">
                    {email}
                </Caption>
            </Box>

            {isAssigned || disabled ? (
                <DisabledButton>
                    <PersonAddAltOutlinedIcon fontSize="small" sx={{ color: getColor(EColors.darkGrey) }} />
                    <Body2 fontWeight={500} color={EColors.darkGrey}>
                        {disabled ? 'Assign' : 'Assigned'}
                    </Body2>
                </DisabledButton>
            ) : (
                <Button
                    startIcon={<PersonAddAltOutlinedIcon />}
                    variant="text"
                    sx={{ transform: 'translatex(12px)' }}
                    onClick={() => onAssignClicked?.(id)}
                >
                    Assign
                </Button>
            )}
        </ListItem>
    );
}
