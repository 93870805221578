import { useRejectInquiry } from 'hooks/queries/BizlyOS/useInquiryActions';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProposalStatus } from '../InquiryAction';
import { RenderInquiryContent } from './RenderInquiryContent';
export const InquiryManager = ({ proposalStatus }: { proposalStatus: string }) => {
    const [status, setStatus] = useState(proposalStatus);

    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };

    const rejectInquiry = useRejectInquiry(venueId, proposalId);

    const handleConfirmDecline = async (data: { rejectionNotes: string; rejectionReason: string }) => {
        rejectInquiry.mutate(data, {
            onSuccess: () => {
                setStatus(ProposalStatus.REJECTED);
            },
            onError: error => {
                console.error('Failed to reject inquiry:', error);
            },
        });
    };

    const handleInitiateDecline = () => {
        setStatus(ProposalStatus.DECLINING);
    };

    const handleBack = () => {
        setStatus(ProposalStatus.NEW_INQUIRY);
    };

    return (
        <RenderInquiryContent
            status={status}
            handleConfirmDecline={handleConfirmDecline}
            handleInitiateDecline={handleInitiateDecline}
            handleBack={handleBack}
            venueId={venueId}
            proposalId={proposalId}
        />
    );
};
