import * as Sentry from '@sentry/react';
import {
    GuestRoomResponse,
    VenuePutType,
    addGuestRoomCategory,
    bizlyOSVenueSearch,
    createEventSpace,
    getEventSpace,
    getEventSpaceType,
    getGuestRoomCategories,
    getGuestRoomCategoryType,
    getVenueById,
    getVenueImagesBatch,
    getVenueMeta,
    getVenueTags,
    postVenueImagesBatch,
    removeEventSpaces,
    removeGuestRoomCategory,
    updateEventSpace,
    updateGuestRoomCategory,
    updateVenue,
} from 'api/bizlyOS';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const LISTINGS_QUERY_KEY = 'listings';
const LISTING_QUERY_KEY = 'listing';
const LISTING_META_QUERY_KEY = 'listing_meta';
const LISTING_IMAGES_BATCH_QUERY_KEY = 'listing_images_batch';
const LISTING_GUESTROOM_TYPES_QUERY_KEY = 'guestroom_types';
const LISTING_GUESTROOM_QUERY_KEY = 'guestroom';
const LISTING_EVENTSPACE_QUERY_KEY = 'eventspace';
const LISTING_EVENTSPACE_TYPES_QUERY_KEY = 'eventspace_types';
const VENUE_TAGS = 'venuetags';

type Response = {
    success: boolean;
    venue: Bizly.Venue;
};

type ImageResponse = {
    success: boolean;
    images: Bizly.VenueImage[];
};

type ListingsQueryParams = {
    page?: number;
    q?: string;
};

const ONE_DAY_IN_MILLISEC = 1000 * 60 * 60 * 24;
const TWO_DAYS_IN_MILLISEC = 1000 * 60 * 60 * 24 * 2;

const FIVE_MINS_IN_MILLISEC = 1000 * 60 * 60 * 24;
const TEN_MINS_IN_MILLISEC = 1000 * 60 * 60 * 24 * 2;

export const useGetListingsQuery = (params?: ListingsQueryParams) => {
    const result = useQuery({
        queryKey: [LISTINGS_QUERY_KEY, params],
        queryFn: () => bizlyOSVenueSearch(params),
        keepPreviousData: true,
        staleTime: FIVE_MINS_IN_MILLISEC,
        cacheTime: TEN_MINS_IN_MILLISEC,
    });

    return result;
};

export const useGetListingMetaQuery = () => {
    const result = useQuery({
        queryKey: [LISTING_META_QUERY_KEY],
        queryFn: () => getVenueMeta(),
        staleTime: ONE_DAY_IN_MILLISEC,
        cacheTime: TWO_DAYS_IN_MILLISEC,
    });

    return result;
};

export const useGetListingImagesBatchQuery = (listingId: string | number) => {
    const result = useQuery({
        queryKey: [LISTING_IMAGES_BATCH_QUERY_KEY, listingId],
        queryFn: () => getVenueImagesBatch(listingId),
        select: data => data.images,
    });

    return result;
};

export const useGetListingQuery = (listingId: string) => {
    const result = useQuery({
        queryKey: [LISTING_QUERY_KEY, listingId],
        queryFn: () => getVenueById(listingId),
        select: data => data.venue,
        onError: (error: Error) => {
            console.error('Error fetching listing:', error);

            Sentry.captureException(error, {
                tags: {
                    component: 'useGetListingQuery',
                    listingId: listingId,
                },
                extra: {
                    listingId: listingId,
                },
            });
        },
    });

    return result;
};

export const useUpdateListingMutation = (listingId: string, onFinish?: (type?: string, message?: string) => void) => {
    const queryClient = useQueryClient();
    return useMutation<Response, Error, VenuePutType>(listing => updateVenue(listingId, listing), {
        onSuccess: response => {
            if (response.success) {
                queryClient.invalidateQueries([LISTING_QUERY_KEY, listingId]);
                onFinish?.('success', 'Successfully Updated');
            }
        },
        onError: (error: Error) => {
            console.error('Error updating listing:', error);

            Sentry.captureException(error, {
                tags: {
                    component: 'useUpdateListingMutation',
                    listingId: listingId,
                },
                extra: {
                    listingId: listingId,
                },
            });

            onFinish?.('error', 'Failed: ' + error.message);
        },
    });
};

export const useGetGuestroomQuery = (listingId: string) => {
    const result = useQuery({
        queryKey: [LISTING_GUESTROOM_QUERY_KEY, listingId],
        queryFn: () => getGuestRoomCategories(listingId),
        select: data => data.guestroomCategories,
    });

    return result;
};

export const useGetGuestroomTypesQuery = () => {
    const result = useQuery({
        queryKey: [LISTING_GUESTROOM_TYPES_QUERY_KEY],
        queryFn: () => getGuestRoomCategoryType(),
        select: data => data.guestroomTypes,
        staleTime: ONE_DAY_IN_MILLISEC,
        cacheTime: TWO_DAYS_IN_MILLISEC,
    });

    return result;
};

export const useCreateGuestroomMutation = (listingId: string) => {
    const queryClient = useQueryClient();
    return useMutation<GuestRoomResponse, Error, Bizly.GuestroomCategory>(
        guestroom => addGuestRoomCategory(listingId, guestroom),
        {
            onSuccess: response => {
                if (response.success) {
                    queryClient.invalidateQueries([LISTING_GUESTROOM_QUERY_KEY, listingId]);
                }
            },
            onError: (error: Error) => {
                console.error('Error adding Guestroom:', error);

                Sentry.captureException(error, {
                    tags: {
                        component: 'useCreateGuestroomMutation',
                        listingId: listingId,
                    },
                    extra: {
                        listingId: listingId,
                    },
                });
            },
        }
    );
};

export const useUpdateGuestroomMutation = (listingId: string, categoryId: string) => {
    const queryClient = useQueryClient();
    return useMutation<GuestRoomResponse, Error, Bizly.GuestroomCategory>(
        guestroom => updateGuestRoomCategory(listingId, categoryId, guestroom),
        {
            onSuccess: response => {
                if (response.success) {
                    queryClient.invalidateQueries([LISTING_GUESTROOM_QUERY_KEY, listingId]);
                }
            },
            onError: (error: Error) => {
                console.error('Error adding Guestroom:', error);

                Sentry.captureException(error, {
                    tags: {
                        component: 'useUpdateGuestroomMutation',
                        listingId: listingId,
                    },
                    extra: {
                        listingId: listingId,
                    },
                });
            },
        }
    );
};

export const useRemoveGuestroomMutation = (listingId: string) => {
    const queryClient = useQueryClient();
    return useMutation<BizlyAPI.DeleteResponse, Error, number[]>(
        guestRoomIds => removeGuestRoomCategory(listingId, guestRoomIds),
        {
            onSuccess: response => {
                if (response.success) {
                    queryClient.invalidateQueries([LISTING_GUESTROOM_QUERY_KEY, listingId]);
                }
            },
        }
    );
};

export const useListingImagesBatchMutation = (listingId: string) => {
    const queryClient = useQueryClient();
    return useMutation<ImageResponse, Error, Partial<Bizly.VenueImage>[]>(
        images => postVenueImagesBatch(listingId, images),
        {
            onSuccess: response => {
                if (response.success) {
                    queryClient.invalidateQueries([LISTING_QUERY_KEY, listingId]);
                    queryClient.invalidateQueries([LISTINGS_QUERY_KEY]);
                }
            },
        }
    );
};

export const useGetEventSpaceTypesQuery = () => {
    const result = useQuery({
        queryKey: [LISTING_EVENTSPACE_TYPES_QUERY_KEY],
        queryFn: () => getEventSpaceType(),
        select: data => data.guestroomTypes,
        staleTime: ONE_DAY_IN_MILLISEC,
        cacheTime: TWO_DAYS_IN_MILLISEC,
    });
    return result;
};

export const useVenueTagsQuery = () => {
    const result = useQuery({
        queryKey: [VENUE_TAGS],
        queryFn: () => getVenueTags(),
        select: data => data.tags,
        staleTime: ONE_DAY_IN_MILLISEC,
        cacheTime: TWO_DAYS_IN_MILLISEC,
    });
    return result;
};

export const useGetEventSpaceQuery = (listingId: string) => {
    const result = useQuery({
        queryKey: [LISTING_EVENTSPACE_QUERY_KEY, listingId],
        queryFn: () => getEventSpace(listingId),
        select: data => data.spaces,
        onError: (error: Error) => {
            console.error('Error Get EventSpace:', error);

            Sentry.captureException(error, {
                tags: {
                    component: 'useGetEventSpaceQuery',
                    listingId: listingId,
                },
                extra: {
                    listingId: listingId,
                },
            });
        },
    });

    return result;
};

export const useCreateEventSpaceMutation = (listingId: string) => {
    const queryClient = useQueryClient();
    return useMutation<{ space: Bizly.OsEventSpace; success: boolean }, Error, Bizly.OsEventSpaceCreate>(
        eventSpace => createEventSpace(listingId, eventSpace),
        {
            onSuccess: response => {
                if (response.success) {
                    queryClient.invalidateQueries([LISTING_EVENTSPACE_QUERY_KEY, listingId]);
                }
            },
            onError: (error: Error) => {
                console.error('Error adding Event Space:', error);

                Sentry.captureException(error, {
                    tags: {
                        component: 'useCreateEventSpaceMutation',
                        listingId: listingId,
                    },
                    extra: {
                        listingId: listingId,
                    },
                });
            },
        }
    );
};

export const useUpdateEventSpaceMutation = (listingId: string, spacesId: number) => {
    const queryClient = useQueryClient();
    return useMutation<{ space: Bizly.OsEventSpace; success: boolean }, Error, Bizly.OsEventSpaceCreate>(
        eventSpace => updateEventSpace(listingId, spacesId, eventSpace),
        {
            onSuccess: response => {
                if (response.success) {
                    queryClient.invalidateQueries([LISTING_EVENTSPACE_QUERY_KEY, listingId]);
                }
            },
            onError: (error: Error) => {
                console.error('Error adding Event Space:', error);

                Sentry.captureException(error, {
                    tags: {
                        component: 'useUpdateEventSpaceMutation',
                        listingId: listingId,
                        spacesId: spacesId,
                    },
                    extra: {
                        listingId: listingId,
                        spacesId: spacesId,
                    },
                });
            },
        }
    );
};

export const useRemoveEventSpaceMutation = (listingId: string) => {
    const queryClient = useQueryClient();
    const removeEventSpacesWithCatch = async (id: number) => {
        try {
            return await removeEventSpaces(listingId, id);
        } catch (error) {
            let errorMessage = 'Something went wrong';
            if (error instanceof Error) errorMessage = error?.message;
            if (typeof error === 'string') errorMessage = error;
            return { success: false, message: errorMessage };
        }
    };

    return useMutation<BizlyAPI.DeleteResponse & { successCount: number; failureCount: number }, Error, number[]>(
        async eventSpaceIds => {
            const response = await Promise.all(eventSpaceIds.map(removeEventSpacesWithCatch));
            const successCount = response.filter(res => res.success).length;
            const failureCount = response.filter(res => !res.success).length;

            return {
                success: response.length === successCount,
                successCount,
                failureCount,
                message: response[0].message,
                deletedIds: eventSpaceIds,
            };
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([LISTING_EVENTSPACE_QUERY_KEY, listingId]);
            },
            onError: (error: Error) => {
                queryClient.invalidateQueries([LISTING_EVENTSPACE_QUERY_KEY, listingId]);
                console.error('Error Remove EventSpace:', error);

                Sentry.captureException(error, {
                    tags: {
                        component: 'useRemoveEventSpaceMutation',
                        listingId: listingId,
                    },
                    extra: {
                        listingId: listingId,
                    },
                });
            },
        }
    );
};
