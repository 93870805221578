import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import CurrencyField from 'components/BizlyOS/InputFields/CurrencyField';
import ImageInputField from 'components/BizlyOS/InputFields/ImageInputField';
import InputField from 'components/BizlyOS/InputFields/InputField';
import { Switch, SwitchField } from 'components/BizlyOS/InputFields/SwitchField';
import {
    CURRENCY_MODAL_DESCRIPTION,
    DATE_FORMAT,
    DEFAULT_CURRENCY_CODE,
    DEFAULT_ROOM_TYPE,
    TypeOfRoomTypes,
} from 'components/BizlyOS/Proposals/utils';
import { SideDrawer } from 'components/BizlyOS/SideDrawer/SideDrawer';
import { Body1, Body2 } from 'components/BizlyOS/Typography/Typography';
import { TGRBooking } from 'components/ProposalForm/types';
import { useGetProposalInquiry, useUpdateProposalInquiry } from 'hooks/queries/BizlyOS/useProposalsQuery';
import { useBizlySnackbar } from 'hooks/useBizlySnackbar';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { tzMoment } from 'utils/moment';
import { RoomBlockInfo } from './RoomBlockInfo';
import { RoomBlockFormData, RoomBlockSchema, genarateUpdatedRoomBlockData } from './utils';

const ProposalContainer = styled(Box)(({ theme: { getColor, EColors, shape, spacing } }) => ({
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    border: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
}));

const ProposalRow = styled(Box)(({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: spacing(1.25),
}));

const Title = styled(Body1)(({ theme: { getColor, EColors, spacing } }) => ({
    paddingBottom: spacing(2.5),
    lineHeight: '100%',
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
    fontWeight: 600,
}));

type RoomBlockSideDrawerProps = {
    drawerOpen: boolean;
    onClose: () => void;
    roomBlock?: TGRBooking;
    selectedRoomType?: TypeOfRoomTypes;
    index?: number;
};

export function RoomBlockSideDrawer({
    roomBlock,
    drawerOpen,
    index,
    selectedRoomType,
    onClose,
}: RoomBlockSideDrawerProps) {
    const { venueId, proposalId } = useParams() as { venueId: string; proposalId: string };
    const { data: proposalInquiry, isLoading } = useGetProposalInquiry(venueId, proposalId);
    const proposal = proposalInquiry?.proposal;
    const guestRooms = proposalInquiry?.guestRooms || [];

    const { bizlyOsSnackbar, bizlyOSErrorSnackbar } = useBizlySnackbar();

    const [isPossible, setIsPossible] = useState(true);

    const updateProposalInquiry = useUpdateProposalInquiry(venueId, proposalId);

    const defaultValues = {
        currencyCode: proposal?.currency?.code || DEFAULT_CURRENCY_CODE,
        proposedRoomRate: roomBlock?.requestedGuests || 0,
        proposedRoomCount: 0,
        copyToNextDay: false,
        copyToAllDays: false,
        images: [],
    };

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState: { errors, isValid, isDirty },
    } = useForm<RoomBlockFormData>({
        resolver: zodResolver(RoomBlockSchema),
        mode: 'onChange',
        defaultValues,
    });

    const isFilled = Boolean(roomBlock?.proposedRoomCount && roomBlock.proposedRoomRate);

    useEffect(() => {
        if (!roomBlock) return;

        // Set form values from roomBlock data
        if (roomBlock.proposedRoomCount) setValue('proposedRoomCount', roomBlock.proposedRoomCount);
        else if (roomBlock.requestedGuests) setValue('proposedRoomCount', roomBlock.requestedGuests);

        if (roomBlock.proposedRoomRate) setValue('proposedRoomRate', roomBlock.proposedRoomRate);
        if (roomBlock.proposedImageUrl) {
            setValue('images', [{ id: crypto.randomUUID(), srcUrl: roomBlock.proposedImageUrl as string, name: '' }]);
        }

        // Set isPossible if both required fields exist
        if (roomBlock.proposedRoomCount && roomBlock.proposedRoomRate) setIsPossible(true);
    }, [roomBlock, setValue]);

    const [currencyCode, copyToAllDays] = watch(['currencyCode', 'copyToAllDays']);

    const onSave = (data: RoomBlockFormData) => {
        if (index === undefined) return;

        const { newGuestRooms, newProposal } = genarateUpdatedRoomBlockData({
            data,
            index,
            guestRooms,
            proposal,
            currencies: proposalInquiry?.options.currencies,
        });

        updateProposalInquiry.mutate(
            { guestRooms: newGuestRooms, proposal: newProposal },
            {
                onSuccess: () => {
                    bizlyOsSnackbar('RoomBlock Updated Successfully!');
                    handleDrawerClose();
                },
                onError: error => {
                    handleDrawerClose();
                    bizlyOSErrorSnackbar(error);
                },
            }
        );
    };

    function handleDrawerClose(isSave = true) {
        reset({
            proposedRoomCount: 0,
            proposedRoomRate: 0,
            images: [],
            currencyCode: isSave ? currencyCode : proposal?.currency?.code || DEFAULT_CURRENCY_CODE,
            copyToNextDay: false,
            copyToAllDays: false,
        });
        setIsPossible(true);
        onClose();
    }

    if (isLoading) {
        return null;
    }

    return (
        <SideDrawer
            drawerOpen={drawerOpen}
            onClose={() => handleDrawerClose(false)}
            footer={
                <Button
                    type="submit"
                    size="medium"
                    fullWidth
                    disabled={(!isValid || !isDirty) && isPossible}
                    loading={updateProposalInquiry.isLoading}
                    onClick={isPossible ? handleSubmit(onSave) : () => handleDrawerClose(false)}
                >
                    Save
                </Button>
            }
            title={`${selectedRoomType} Room Details`}
        >
            <Box display="flex" flexDirection="column" gap={2.5}>
                <RoomBlockInfo
                    infos={[
                        { lable: 'Date', value: tzMoment(roomBlock?.date).format(DATE_FORMAT) },
                        { lable: 'Room Type', value: selectedRoomType || DEFAULT_ROOM_TYPE },
                        { lable: 'No. of Rooms', value: roomBlock?.requestedGuests || 0 },
                    ]}
                />

                <SwitchField
                    label="Can you accommodate for this date?"
                    value={isPossible}
                    disabled={isFilled}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setIsPossible(event.target.checked);
                    }}
                />

                {isPossible && (
                    <>
                        <ProposalContainer>
                            <Title>Your Proposal</Title>
                            <ProposalRow>
                                <Controller
                                    name="proposedRoomCount"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <InputField
                                                {...field}
                                                required
                                                error={errors.proposedRoomCount}
                                                label="Available Rooms"
                                                placeholder="0"
                                                type="number"
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    name="proposedRoomRate"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <CurrencyField
                                                type="number"
                                                {...field}
                                                required
                                                currencyCode={currencyCode}
                                                onCurrencyCodeChange={code =>
                                                    setValue('currencyCode', code, {
                                                        shouldValidate: true,
                                                        shouldDirty: true,
                                                    })
                                                }
                                                error={errors.proposedRoomRate}
                                                label="Nightly Rate"
                                                placeholder="0.00"
                                                currencyModalDescription={CURRENCY_MODAL_DESCRIPTION}
                                                currencyOptions={proposalInquiry?.options.currencies || []}
                                            />
                                        );
                                    }}
                                />
                            </ProposalRow>
                            <Controller
                                name="images"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <ImageInputField
                                            required
                                            label="Room Image"
                                            limit={1}
                                            value={field.value}
                                            onChange={field.onChange}
                                            error={!!errors.images}
                                            listingId={proposalId}
                                        />
                                    );
                                }}
                            />
                        </ProposalContainer>
                        {guestRooms.length > 1 && (
                            <ProposalContainer>
                                <Title>Reuse Room Details</Title>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Body2 fontWeight={500}>Copy to next day</Body2>
                                    <Controller
                                        name="copyToNextDay"
                                        control={control}
                                        render={({ field }) => {
                                            return <Switch {...field} disabled={copyToAllDays} checked={field.value} />;
                                        }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Body2 fontWeight={500}>Copy to all days</Body2>
                                    <Controller
                                        name="copyToAllDays"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <Switch
                                                    checked={field.value}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (e.target.checked) setValue('copyToNextDay', true);
                                                        field.onChange(e);
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </Box>
                            </ProposalContainer>
                        )}
                    </>
                )}
            </Box>
        </SideDrawer>
    );
}
