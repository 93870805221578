import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { postProposalFormContact } from 'api/proposalForms';
import { PROPOSAL_QUERIES } from 'hooks/queries/BizlyOS/useProposalsQuery';
import {
    StaffMemberType,
    useInviteStaffMemberMutation,
    useStaffRoles,
    useUpdateStaffMemberMutation,
} from 'hooks/queries/BizlyOS/useStaffQuery';
import { useBizlySnackbar } from 'hooks/useBizlySnackbar';
import { useBizlyOSUser } from 'providers/bizly-os-user';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { SideDrawer } from '../SideDrawer/SideDrawer';
import { DrawerActionButtons } from './DrawerActionButtons';
import { DrawerHeader } from './DrawerHeader';
import { FormFields } from './FormFields';
import {
    STAFF_DEFAULT_VALUE,
    STAFF_FORM_DEFAULT_VALUES,
    StaffFormSchema,
    StaffFormsData,
    formatStaffMember,
    getFilledStaffObject,
} from './utils';

const Container = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2.5),
}));

type InviteStaffDrawerProps = {
    title?: string;
    drawerOpen: boolean;
    setDrawerOpen: (toggle: boolean) => void;
    selectedStaffMember?: StaffMemberType;
    addMultiple?: boolean;
    goBackEnabled?: boolean;
    isEdit?: boolean;
    isView?: boolean;
    addStaffByDefault?: boolean;
};

export const InviteStaffDrawer = ({
    title = 'Invite Colleagues',
    drawerOpen,
    isEdit = false,
    isView = false,
    addMultiple = false,
    goBackEnabled = false,
    selectedStaffMember,
    setDrawerOpen,
    addStaffByDefault = false,
}: InviteStaffDrawerProps) => {
    const { bizlyOsSnackbar, bizlyOSErrorSnackbar } = useBizlySnackbar();

    const { venueId } = useBizlyOSUser();
    const { proposalId } = useParams() as { proposalId: string };

    const inviteStaffMemberMutation = useInviteStaffMemberMutation(venueId as string);

    const updateStaffMutation = useUpdateStaffMemberMutation(
        venueId as string,
        selectedStaffMember?.id.toString() || ''
    );

    const queryClient = useQueryClient();

    const { roles } = useStaffRoles(venueId as string);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        trigger,
        reset,
    } = useForm<StaffFormsData>({
        resolver: zodResolver(StaffFormSchema),
        defaultValues: STAFF_FORM_DEFAULT_VALUES,
        mode: 'onTouched',
        disabled: isView,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'forms',
    });

    useEffect(() => {
        if ((isEdit || isView) && selectedStaffMember && roles) {
            reset({
                forms: [getFilledStaffObject(selectedStaffMember, roles)],
            });
        }
    }, [isEdit, isView, selectedStaffMember, roles, reset]);

    const onCreate = async (data: StaffFormsData) => {
        const staffMembers = data.forms.map(staffMember => formatStaffMember(staffMember));

        try {
            const results = await Promise.all(
                staffMembers.map(member => inviteStaffMemberMutation.mutateAsync(member))
            );

            if (addStaffByDefault) {
                await postProposalFormContact(venueId, proposalId, staffMembers[0]);
            }

            if (results.every(res => res.success)) {
                bizlyOsSnackbar('Successfully invited new user!');
                queryClient.invalidateQueries([PROPOSAL_QUERIES.PROPOSAL_INQURY, venueId, proposalId]);
                reset();
                setDrawerOpen(false);
            } else {
                bizlyOSErrorSnackbar();
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message);
                bizlyOSErrorSnackbar();
            }
        }
    };

    const onEdit = async (data: StaffFormsData) => {
        const newStaffMember = data.forms[0];

        updateStaffMutation.mutate(formatStaffMember(newStaffMember), {
            onSuccess: () => {
                onDrawerClose();
                bizlyOsSnackbar('Successfully Updated!');
            },
            onError: error => {
                bizlyOSErrorSnackbar(error);
                onDrawerClose();
            },
        });
    };

    const onSubmit = async (data: StaffFormsData) => {
        if (isEdit) {
            onEdit(data);
        } else {
            onCreate(data);
        }
    };

    const handleAddAnother = async () => {
        const isValid = await trigger();
        if (isValid) {
            append({ ...STAFF_DEFAULT_VALUE });
        } else {
            console.error('Please fill out all required fields before adding another.');
        }
    };

    const onDrawerClose = () => {
        setDrawerOpen(false);
        reset(STAFF_FORM_DEFAULT_VALUES);
    };

    return (
        <SideDrawer
            drawerOpen={drawerOpen}
            onClose={onDrawerClose}
            footer={
                !isView ? (
                    <DrawerActionButtons
                        onCancel={() => setDrawerOpen(false)}
                        onSubmit={handleSubmit(onSubmit)}
                        isSubmitting={inviteStaffMemberMutation.isLoading || updateStaffMutation.isLoading}
                        isValid={isValid}
                        goBackEnabled={goBackEnabled}
                    />
                ) : null
            }
            title={title}
        >
            <Container>
                <DrawerHeader
                    goBackEnabled={goBackEnabled}
                    isEdit={isEdit}
                    addMultiple={addMultiple}
                    handleAddAnother={handleAddAnother}
                />

                {fields.map((field, index) => (
                    <FormFields
                        key={field.id}
                        index={index}
                        remove={() => remove(index)}
                        control={control}
                        errors={errors}
                        roles={roles || []}
                        disableEmail={isEdit || isView}
                        isEdit={isEdit}
                        isActiveUser={Boolean(selectedStaffMember?.lastActiveAt)}
                    />
                ))}
            </Container>
        </SideDrawer>
    );
};
