import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { BIZLYOS_CONTACT_TYPES, DEPARTMENT_OPTIONS } from 'shared';
import { Button } from '../Button/Button';
import { BorderedContainer } from '../GridContainer';
import InputField from '../InputFields/InputField';
import SelectField from '../InputFields/SelectField';
import { Switch } from '../InputFields/SwitchField';
import { Body2 } from '../Typography/Typography';
import { StaffFormsData, StaffRole } from './utils';

const PERMISSION_NOT_SELECTED_VALUE = -1;

const FeildContainer = styled(BorderedContainer)(({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: spacing(1.25),
    rowGap: spacing(2.5),
    padding: spacing(2.5),
    height: 'auto',
}));

const SwitchContainer = styled(Box)<{ isOn: boolean }>(({ theme: { getColor, EColors, shape, spacing } }) => ({
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.2),
    padding: spacing(2.5),
    borderRadius: shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const FormFields = ({
    index,
    remove,
    control,
    errors,
    roles,
    disableEmail,
    isEdit,
    isActiveUser,
}: {
    index: number;
    control: Control<StaffFormsData>;
    errors: FieldErrors<StaffFormsData>;
    roles: StaffRole[];
    disableEmail: boolean;
    isEdit: boolean;
    isActiveUser: boolean;
    remove: () => void;
}) => {
    const isFirstItem = index === 0;

    return (
        <FeildContainer>
            <Controller
                name={`forms.${index}.firstName`}
                control={control}
                render={({ field }) => (
                    <InputField
                        {...field}
                        required
                        label="First Name"
                        error={errors.forms?.[index]?.firstName}
                        disabled={isEdit && isActiveUser}
                    />
                )}
            />

            <Controller
                name={`forms.${index}.lastName`}
                control={control}
                render={({ field }) => (
                    <InputField
                        {...field}
                        required
                        label="Last Name"
                        error={errors.forms?.[index]?.lastName}
                        disabled={isEdit && isActiveUser}
                    />
                )}
            />

            <Controller
                name={`forms.${index}.email`}
                control={control}
                render={({ field }) => (
                    <InputField
                        {...field}
                        required
                        label="Business Email"
                        error={errors.forms?.[index]?.email}
                        disabled={disableEmail}
                    />
                )}
            />

            <Controller
                name={`forms.${index}.permission`}
                control={control}
                render={({ field }) => (
                    <SelectField
                        {...field}
                        error={errors.forms?.[index]?.permission}
                        required
                        label="Permission"
                        notSelectedValue={PERMISSION_NOT_SELECTED_VALUE}
                        options={roles.map(({ name, id }) => ({
                            value: id,
                            label: name,
                            id,
                        }))}
                    />
                )}
            />

            <Controller
                name={`forms.${index}.contactType`}
                control={control}
                render={({ field }) => (
                    <SelectField
                        {...field}
                        error={errors.forms?.[index]?.contactType}
                        required
                        label="Contact Type"
                        options={BIZLYOS_CONTACT_TYPES.map(({ name, id }) => ({
                            value: id,
                            label: name,
                            id,
                        }))}
                    />
                )}
            />

            <Controller
                name={`forms.${index}.department`}
                control={control}
                render={({ field }) => (
                    <SelectField
                        {...field}
                        error={errors.forms?.[index]?.department}
                        required
                        label="Department"
                        options={DEPARTMENT_OPTIONS.map(({ name, id }) => ({
                            value: id,
                            label: name,
                            id,
                        }))}
                    />
                )}
            />
            <Box gridColumn="1/3">
                <SwitchContainer>
                    <Body2 fontWeight={500}>Should this contact receive all new inquiry emails?</Body2>
                    <Controller
                        name={`forms.${index}.receiveEmails`}
                        control={control}
                        render={({ field }) => <Switch {...field} checked={field.value} />}
                    />
                </SwitchContainer>
            </Box>
            {!isFirstItem && (
                <Box display="flex" height="21px">
                    <Button variant="text" color="error" onClick={remove}>
                        Remove
                    </Button>
                </Box>
            )}
        </FeildContainer>
    );
};
