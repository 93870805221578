import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import ImageInputField from 'components/BizlyOS/InputFields/ImageInputField';
import InputField from 'components/BizlyOS/InputFields/InputField';
import InputFieldWithToggle from 'components/BizlyOS/InputFields/InputFieldWithToggle';
import RichTextEditorField from 'components/BizlyOS/InputFields/RichTextEditorField';
import SelectField from 'components/BizlyOS/InputFields/SelectField';
import { IMPERIAL_UNIT, METRIC_UNIT, numericSchema } from 'components/BizlyOS/Proposals/utils';
import { SideDrawer } from 'components/BizlyOS/SideDrawer/SideDrawer';
import {
    useCreateEventSpaceMutation,
    useGetEventSpaceTypesQuery,
    useUpdateEventSpaceMutation,
} from 'hooks/queries/BizlyOS/useListingQuery';
import { useBizlySnackbar } from 'hooks/useBizlySnackbar';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

const Row = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2.5),
}));

const defaultValues = {
    name: '',
    typeId: 0,
    size: { value: 0, unit: IMPERIAL_UNIT },
    description: '',
    maxCapacity: 0,
    images: [],
};

const ImageSchema = z
    .array(
        z.object({
            id: z.string(),
            srcUrl: z.string(),
            name: z.string(),
            order: z.number().optional(),
            description: z.string().optional(),
        })
    )
    .min(1, 'Images are required');

const AddEventSpaceSchema = z.object({
    name: z.string().min(1, 'Required'),
    typeId: z.number().min(1, 'Required'),
    size: z.object({
        value: numericSchema({ min: 1 }),
        unit: z.string(),
    }),
    description: z.string().min(1, 'Required'),
    maxCapacity: numericSchema({ min: 1 }),
    images: ImageSchema,
});

type AddEventSpaceSchemaFormData = z.infer<typeof AddEventSpaceSchema>;

type AddSpaceSideDrawerProps = {
    isEdit?: boolean;
    drawerOpen: boolean;
    onClose: (closeSelectDrawer: boolean) => void;
    venueEventSpaces?: Bizly.OsEventSpace[];
    proposedVenueSpace?: Bizly.OsEventSpace;
    editingVenueSpaceId?: number;
    setProposedVenueSpace: Dispatch<SetStateAction<Bizly.OsEventSpace | undefined>>;
};

export function VenueSpaceDrawer({
    drawerOpen,
    editingVenueSpaceId,
    venueEventSpaces,
    onClose,
    setProposedVenueSpace,
}: AddSpaceSideDrawerProps) {
    const { venueId } = useParams() as { venueId: string; proposalId: string };

    const { data: eventSpaceTypes = [] } = useGetEventSpaceTypesQuery();
    const useCreateEventSpace = useCreateEventSpaceMutation(venueId);
    const useUpdateEventSpace = useUpdateEventSpaceMutation(venueId, editingVenueSpaceId || 0);

    const { bizlyOsSnackbar, bizlyOSErrorSnackbar } = useBizlySnackbar();

    const isEdit = !!editingVenueSpaceId;

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isValid, isDirty },
    } = useForm<AddEventSpaceSchemaFormData>({
        resolver: zodResolver(AddEventSpaceSchema),
        defaultValues,
        mode: 'onChange',
    });

    const onError = (error: Error) => {
        handleClose();
        bizlyOSErrorSnackbar(error);
    };

    useEffect(() => {
        if (!editingVenueSpaceId || !venueEventSpaces) {
            reset(defaultValues);
            return;
        }

        const editingVenueSpace = venueEventSpaces?.find(space => space.id === editingVenueSpaceId);
        if (editingVenueSpace) {
            reset({
                name: editingVenueSpace.name,
                typeId: editingVenueSpace.type.id,
                size: {
                    value: Number(editingVenueSpace.size),
                    unit: editingVenueSpace.metric ? METRIC_UNIT : IMPERIAL_UNIT,
                },
                description: editingVenueSpace.description,
                maxCapacity: editingVenueSpace.maxCapacity || 0,
                images:
                    editingVenueSpace.images?.map(image => ({
                        id: `${image.id}` as string,
                        srcUrl: image.srcUrl,
                        name: image.name || '',
                        description: image.description || '',
                        order: image.order || 0,
                    })) || [],
            });
        }
    }, [editingVenueSpaceId, venueEventSpaces, reset]);

    const onCreate = (data: Bizly.OsEventSpaceCreate) => {
        useCreateEventSpace.mutate(data, {
            onSuccess: ({ space }) => {
                setProposedVenueSpace(space);
                handleClose(true);
                bizlyOsSnackbar('Space created successfully');
            },
            onError,
        });
    };

    const onEdit = (data: Bizly.OsEventSpaceCreate) => {
        useUpdateEventSpace.mutate(data, {
            onSuccess: ({ space }) => {
                setProposedVenueSpace(space);
                handleClose();
                bizlyOsSnackbar('Space updated successfully');
            },
            onError,
        });
    };

    const onSave = (data: AddEventSpaceSchemaFormData) => {
        const formattedData = {
            name: data.name,
            description: data.description,
            typeId: data.typeId,
            size: Number(data.size?.value?.toString().replace(/[^0-9.]/g, '') || 0),
            metric: data.size.unit === METRIC_UNIT,
            maxCapacity: data.maxCapacity || 0,
            images: data.images.map((image, index) => ({
                ...image,
                order: index,
                description: image.description || '',
            })),
            capacities: {
                maximumCapacity: data.maxCapacity || 0,
            },
        };

        if (isEdit) {
            onEdit(formattedData);
        } else {
            onCreate(formattedData);
        }
    };

    const handleClose = (closeSelectDrawer = false) => {
        reset();
        onClose(closeSelectDrawer);
    };

    return (
        <SideDrawer
            drawerOpen={drawerOpen}
            onClose={handleClose}
            title={`${isEdit ? 'Edit' : 'Create'} Space`}
            invisibleBackdrop
            footer={
                <Row>
                    <Button variant="outline" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!isValid || !isDirty}
                        onClick={handleSubmit(onSave)}
                        loading={useCreateEventSpace.isLoading || useUpdateEventSpace.isLoading}
                    >
                        Save
                    </Button>
                </Row>
            }
        >
            <Box display="flex" flexDirection="column" gap={2.5}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => {
                        return <InputField {...field} required error={errors.name} label="Name" />;
                    }}
                />
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => {
                        return (
                            <RichTextEditorField {...field} required error={errors.description} label="Description" />
                        );
                    }}
                />

                <Controller
                    name="typeId"
                    control={control}
                    render={props => {
                        const { field } = props;
                        return (
                            <SelectField
                                {...field}
                                value={field.value ? field.value : ''}
                                required
                                error={errors.typeId}
                                label="Type"
                                options={eventSpaceTypes.map(type => ({
                                    label: type.name,
                                    value: type.id,
                                }))}
                            />
                        );
                    }}
                />

                <Row>
                    <Controller
                        name="size"
                        control={control}
                        render={({ field }) => {
                            return (
                                <InputFieldWithToggle
                                    type="number"
                                    {...field}
                                    value={field.value.value}
                                    toggleValue={field.value.unit}
                                    onChange={event =>
                                        field.onChange({
                                            value: event.target.value,
                                            unit: field.value.unit,
                                        })
                                    }
                                    onChangeToggle={toggleValue =>
                                        field.onChange({
                                            value: field.value.value,
                                            unit: toggleValue,
                                        })
                                    }
                                    required
                                    error={errors.size?.value}
                                    label="Size"
                                    toggleOptions={[
                                        { label: 'Sq. Ft', value: IMPERIAL_UNIT },
                                        { label: 'Sq. Mt', value: METRIC_UNIT },
                                    ]}
                                />
                            );
                        }}
                    />
                    <Controller
                        name="maxCapacity"
                        control={control}
                        render={({ field }) => {
                            return (
                                <InputField
                                    {...field}
                                    type="number"
                                    required
                                    error={errors.maxCapacity}
                                    label="Max Capacity"
                                />
                            );
                        }}
                    />
                </Row>

                <Controller
                    name="images"
                    control={control}
                    render={({ field }) => {
                        return (
                            <ImageInputField
                                required
                                label="Space Images"
                                limit={6}
                                value={field.value}
                                onChange={field.onChange}
                                error={!!errors.images}
                                listingId={venueId}
                            />
                        );
                    }}
                />
            </Box>
        </SideDrawer>
    );
}
